@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");

.quill {
    width: 100%;
    height: 18em !important;
    padding-bottom: 2em;
}

.ql-toolbar {
    border: 1px solid #D6D6D6 !important;
    background-color: #EAEAEA;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.ql-toolbar.ql-snow {
    border-bottom: 2px solid #D6D6D6 !important;
}

.ql-container {
    border: 1px solid #D6D6D6 !important;
    background-color: #EAEAEA;
    color: #333333;
    font-size: 1em;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-family: 'Inter', 'sans-serif' !important;
}

.ql-snow.ql-toolbar button:hover {
    color: #333333 !important;
}