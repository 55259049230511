@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Inter', 'sans-serif';
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
        margin: 0;
    }
    body {
        position: relative;
    }
    a {
        text-decoration: none;
    }
    p {
        margin: 0;
    }
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    ::-webkit-scrollbar-track {
        background: #EAEAEA;
    }
    ::-webkit-scrollbar-thumb {
        background: #2b344d; 
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #222839; 
    }
    ::-ms-reveal {
        display: none
    }
}

@layer components {
    .whm-divider {
        @apply my-2 border-OlympiaBorder border-[1px] border-solid mx-auto
    }
    .navbar-icon-box {
        @apply flex justify-center items-center m-5 p-3 bg-OlympiaPurple/20 rounded-[50%] hover:rounded-xl hover:m-4 hover:p-4 hover:bg-OlympiaDarkGreyBlue
        fill-[#333333] hover:fill-[#d6a156] transition-all duration-500 cursor-pointer shadow-halo
    }
    .navbar-icon-selected {
        @apply m-4 p-4 bg-OlympiaDarkPurple fill-[#d6a156]
    }
    .dashboard-menu-box {
        @apply w-full h-full bg-OlympiaLightGrey/50 border-r-[1px] border-OlympiaBorder
    }
    .dashboard-title-box {
        @apply w-full h-full bg-OlympiaWhite relative
        after:content-[""] after:absolute after:right-0 after:top-6 after:h-[90%]
        after:w-[1px] after:bg-OlympiaBorder
    }
    .dashboard-detail-box {
        @apply w-full h-full bg-OlympiaWhite
    }
    .login-button {
        @apply bg-OlympiaDark/90 flex justify-center items-center
        text-OlympiaWhite rounded-[12px] w-full text-xl px-4 py-5
        disabled:bg-OlympiaSunYellow disabled:cursor-not-allowed drop-shadow-md
        hover:bg-OlympiaDark transition duration-150 ease-in-out
    }
    .blue-button {
        @apply bg-OlympiaBlue flex justify-center items-center
        text-OlympiaWhite rounded-[5px] w-full text-xl px-4 py-2 drop-shadow-md
        hover:bg-[#46b9ab] transition duration-150 ease-in-out
    }
    .small-dark-purple-button {
        @apply bg-OlympiaDarkPurple w-fit px-3 py-1 text-[1em] flex justify-center items-center
        rounded-[5px] text-OlympiaWhite cursor-pointer shadow-lg hover:scale-105
        transition-all duration-200
    }
    .small-purple-button {
        @apply bg-OlympiaDarkPurple/60 w-fit px-3 py-1 text-[1em] flex justify-center items-center
        rounded-[5px] text-OlympiaWhite cursor-pointer shadow-lg hover:scale-105
        transition-all duration-200
    }
    .small-light-red-button {
        @apply bg-OlympiaRed/60 w-fit px-3 py-1 text-[1em] flex justify-center items-center
        rounded-[5px] text-OlympiaWhite cursor-pointer shadow-lg hover:scale-105
        transition-all duration-200
    }
    .delete-button {
        @apply bg-OlympiaRed w-fit mx-2 px-3 py-1 text-[1em] flex justify-center items-center
        rounded-[5px] text-OlympiaWhite cursor-pointer shadow-lg hover:scale-105
        transition-all duration-200
    }
    .save-button {
        @apply bg-OlympiaBlue w-fit mx-2 px-3 py-1 text-[1em] flex justify-center items-center
        rounded-[5px] text-OlympiaWhite cursor-pointer shadow-lg hover:scale-105
        transition-all duration-200
    }
}